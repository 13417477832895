import React from 'react'
import Layout from '../components/layout'

import { Helmet } from 'react-helmet'
import Back from '../components/back';
class ContactIndex extends React.Component {
  render() {
    const formStyle = {
      border: '1px solid #9e9e9e',
      borderRadius: '3px',
      fontFamily: 'inherit',
      fontSize: '1rem',
      padding: '.5rem',
      margin: '.5rem',
      maxWidth: '25rem',
      width: 'calc(100vw - 2rem)'
    };

    return (
      <Layout location={this.props.location}>
        <div style={{ background: '#fff' }}>
          <Helmet title="Contact Dana Royal" />
          
          <Back/>
          <div style={{textAlign:'center', marginBottom: '5vh'}}>
            <h1>Contact</h1>
            <div>
              <form name="contact" method="POST" data-netlify="true">
                <input type="hidden" name="form-name" value="contact" />
                <div><input type="text" name="name" placeholder="Name" style={formStyle}/></div>
                <div><input type="email" name="email" placeholder="Email" style={formStyle} /></div>
                <div><textarea name="message" placeholder="Your message" rows="8" style={formStyle}></textarea></div>
                <div>
                  <button type="submit" style={{
                    background: 'inherit',
                    border: '1px solid rgba(51, 51, 51, 0.6)',
                    borderRadius: '3px',
                    color: '#333333',
                    cursor: 'pointer',
                    fontFamily: 'inherit',
                    fontSize: '1rem',
                    lineHeight: '2rem',
                    padding: '.25rem 1.5rem',
                  }}>
                    Send
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default ContactIndex
